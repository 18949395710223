import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Button, Card, ListGroup } from 'react-bootstrap';
import MessageBox from '../components/MessageBox';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SkeletonCartScreen from '../components/skeletons/SkeletonCartScreen';

export default function CartScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const updateCartHandler = async (item, quantity) => {
    const { data } = await axios.get(`/api/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };

  const removeItemHandler = (item) => {
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
  };

  const checkoutHandler = () => {
    navigate('/signin?redirect=/shipping');
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Calculate itemsPrice
  const itemsPrice = cartItems.reduce(
    (a, c) => a + (c.salePrice ? c.salePrice : c.price) * c.quantity,
    0
  );

  return (
    <div className='content'>
      {isLoading ? (
        <SkeletonCartScreen />
      ) : (
        <>
          <Helmet>
            <title>Shopping Cart</title>
          </Helmet>
          <br />
          <h4 className='box'>Shopping Cart</h4>
          <Row>
            <Col xs={12} md={8} className='box'>
              {cartItems.length === 0 ? (
                <MessageBox>
                  Cart is empty. <Link to='/'>Go Shopping</Link>
                </MessageBox>
              ) : (
                <ListGroup>
                  {cartItems.map((item) => (
                    <ListGroup.Item
                      key={item._id}
                      className='d-flex flex-column'
                    >
                      <Row className='align-items-center flex-wrap'>
                        <Col xs={12} sm={4} className='mb-2 text-center'>
                          <img
                            src={item.image}
                            alt={item.name}
                            className='img-fluid rounded img-thumbnail'
                          />
                          <Link to={`/product/${item.slug}`}>{item.name}</Link>
                        </Col>
                        <Col xs={12} sm={3} className='mb-2 text-center'>
                          <Button
                            onClick={() =>
                              updateCartHandler(item, item.quantity - 1)
                            }
                            variant='light'
                            disabled={item.quantity === 1}
                          >
                            <i className='fas fa-minus-circle'></i>
                          </Button>{' '}
                          <span>{item.quantity}</span>{' '}
                          <Button
                            variant='light'
                            onClick={() =>
                              updateCartHandler(item, item.quantity + 1)
                            }
                            disabled={item.quantity === item.countInStock}
                          >
                            <i className='fas fa-plus-circle'></i>
                          </Button>
                        </Col>
                        <Col xs={12} sm={3} className='mb-2 text-center'>
                          {item.salePrice ? (
                            <>
                              <span
                                style={{
                                  textDecoration: 'line-through',
                                  color: 'gray',
                                }}
                              >
                                ${item.price.toFixed(2)}
                              </span>{' '}
                              <span
                                style={{ color: 'red', fontWeight: 'bold' }}
                              >
                                ${item.salePrice}
                              </span>
                            </>
                          ) : (
                            <span>${item.price}</span>
                          )}
                        </Col>
                        <Col xs={12} sm={2} className='mb-2 text-center'>
                          <Button
                            onClick={() => removeItemHandler(item)}
                            variant='light'
                          >
                            <i className='fas fa-trash'></i>
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Col>

            <Col xs={12} md={4} className='summary-card'>
              <Card>
                <Card.Body>
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      <h3>
                        Subtotal (
                        {cartItems.reduce((a, c) => a + c.quantity, 0)} items) :
                        ${itemsPrice.toFixed(2)}
                      </h3>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <p className='order-shipping-note'>
                        {itemsPrice > 100
                          ? '(USA Only) Orders shipped Mon-Fri, Free shipping on this order!'
                          : '(USA Only) Orders shipped Mon-Fri, Flat shipping fee of $10 for orders under $100.'}
                      </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-grid'>
                        <Button
                          type='button'
                          variant='primary'
                          onClick={checkoutHandler}
                          disabled={cartItems.length === 0}
                        >
                          Proceed to Checkout
                        </Button>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
