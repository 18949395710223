import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

export default function DesignContentCard({ section, imagePosition = 'left' }) {
  const hasImages = section.images && section.images.length > 0;

  return (
    <Row className='my-4 align-items-stretch'>
      {hasImages ? (
        imagePosition === 'left' ? (
          <>
            <Col
              md={4}
              className='d-flex flex-wrap justify-content-center align-items-start'
            >
              {section.images.map((img, idx) => (
                <div
                  key={idx}
                  className='box'
                  style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '120px',
                    flex: '1 1 auto',
                  }}
                >
                  <Image
                    src={img.url}
                    alt={`${section.title} ${idx + 1}`}
                    className='img-responsive'
                    style={{
                      maxHeight: '200px',
                      objectFit: 'cover',
                      padding: '5px',
                    }}
                  />
                  {img.name && (
                    <div style={{ padding: '5px', fontSize: '12px' }}>
                      {img.name}
                    </div>
                  )}
                </div>
              ))}
            </Col>
            <Col md={8} className='d-flex'>
              <div className='box' style={{ width: '100%' }}>
                <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  {section.title}
                </h3>
                {section.paragraphs.map((paragraph, index) => (
                  <p key={index} style={{ marginBottom: '10px' }}>
                    {paragraph}
                  </p>
                ))}
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col md={8} className='d-flex'>
              <div className='box' style={{ width: '100%' }}>
                <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  {section.title}
                </h3>
                {section.paragraphs.map((paragraph, index) => (
                  <p key={index} style={{ marginBottom: '10px' }}>
                    {paragraph}
                  </p>
                ))}
              </div>
            </Col>
            <Col
              md={4}
              className='d-flex flex-wrap justify-content-center align-items-start'
            >
              {section.images.map((img, idx) => (
                <div
                  key={idx}
                  className='box'
                  style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '120px',
                    flex: '1 1 auto',
                  }}
                >
                  <Image
                    src={img.url}
                    alt={`${section.title} ${idx + 1}`}
                    className='img-responsive'
                    style={{
                      maxHeight: '200px',
                      objectFit: 'cover',
                      padding: '5px',
                    }}
                  />
                  {img.name && (
                    <div style={{ padding: '5px', fontSize: '12px' }}>
                      {img.name}
                    </div>
                  )}
                </div>
              ))}
            </Col>
          </>
        )
      ) : (
        <Col md={12}>
          <div className='box'>
            <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {section.title}
            </h3>
            {section.paragraphs.map((paragraph, index) => (
              <p key={index} style={{ marginBottom: '10px' }}>
                {paragraph}
              </p>
            ))}
          </div>
        </Col>
      )}
    </Row>
  );
}
